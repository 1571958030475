import { Prop, Watch } from 'vue-property-decorator';

import CmsAccordion from '@/commoncomponents/CmsAccordion/CmsAccordion.vue';
import CmsCardCarousel from '@/commoncomponents/CmsCardCarousel/CmsCardCarousel.vue';
import CmsCardComponent from '@/commoncomponents/CmsCardComponent/CmsCardComponent.vue';
import CmsLabelComponent from '@/commoncomponents/CmsLabelComponent/CmsLabelComponent.vue';
import CmsTagComponent from '@/commoncomponents/CmsTagComponent/CmsTagComponent.vue';
import CmsCatalogFacets from '@/commoncomponents/CmsCatalogFacets/CatalogFacetsComponent.vue';
import BreadcrumbComponent from '@/commoncomponents/breadcrumbComponent/BreadcrumbComponent.vue';
import MobileScreenUnavailable from '@/commoncomponents/mobileScreenUnavailable/MobileScreenUnavailable.vue';
import DeviceWidthCheckerMixin from '@/mixins/deviceWidthChecker.mixin';
import { Component, Mixins } from 'vue-property-decorator';
import AcademicConnections from '../../../assets/images/cms/academic_connections.svg';
import Checkmark from '../../../assets/images/cms/checkmark.svg';
import ChevronRight from '../../../assets/images/cms/chevron_right_nav_link.svg';
import DownloadIcon from '../../../assets/images/cms/download_link_blue.svg';
import InfoIcon from '../../../assets/images/cms/information_gray.svg';
import ChipRemoveIcon from '../../../assets/images/cms/chip_remove_white.svg';
import TagIcon from '../../../assets/images/cms/white-tag.svg';
import IndeterminateCheckboxIcon from '../../../assets/images/cms/indeterminate-checkbox.svg';
import Bullet from '../../../assets/images/cms/link_bullet_blue.svg';
import SetUpIcon from '../../../assets/images/cms/man_woman.svg';
import MaterialsIcon from '../../../assets/images/cms/materials_box.svg';
import ResourceLink from '../../../assets/images/cms/resource_link.svg';
import SmileyFace from '../../../assets/images/cms/smiley_face.svg';
import NoResultsIcon from '../../../assets/images/cms/search-icon.svg';
import programListStore from '@/store/modules/programList';
import accountListStore from '@/store/modules/accountsList';
import catalogFilterStore from '@/store/modules/catalogFilterStore';
import collectionsStore from '@/store/modules/collectionsStore';

import BouncingPreloaderComponent from '@/commoncomponents/bouncingpreloadercomponent/BouncingPreloaderComponent.vue';
import {
  AlgoliaHitObject,
  algoliaToCardData,
  algoliaFacets,
  getCopyrightMessage,
  AlgoliaFacetValue,
  checkCMSEnabledReRoute,
  ContentCardData,
} from '@/utilities/cmsUtilities';
import { AlgoliaIndex } from '@/services/cms/algoliaService';
import PaginationComponent from '@/commoncomponents/paginationcomponent/PaginationComponent.vue';
import APP_CONST from '@/constants/AppConst';
import APP_UTILITIES from '@/utilities/commonFunctions';
import { getCourseCardStatus } from '@/utilities/cms/courseStatus';
import OffCanvas from '@/commoncomponents/OffCanvas.vue';
import MyCollectionsFlyout from '@/components/myCollectionsFlyout/MyCollectionsFlyout.vue';
import { getUserCollections } from "@/services/collections/api";
@Component({
  components: {
    MyCollectionsFlyout,
    'bread-crumb': BreadcrumbComponent,
    'mobile-unavailable-screen': MobileScreenUnavailable,
    'cms-card': CmsCardComponent,
    'collapsible-layout-card': CmsAccordion,
    'cms-tag-list': CmsTagComponent,
    'cms-content-label': CmsLabelComponent,
    'card-carousel': CmsCardCarousel,
    'bouncing-preloader': BouncingPreloaderComponent,
    'cms-catalog-facets': CmsCatalogFacets,
    pagination: PaginationComponent,
    'OffCanvas': OffCanvas
  }
})

export default class CatalogPage extends Mixins(
  DeviceWidthCheckerMixin
) {
  //Images from assets folder
  checkmarkImage = Checkmark;
  resourceLinkImage = Bullet;
  navLinksChevron = ChevronRight;
  linkIcon = ResourceLink;
  smileyFace = SmileyFace;
  academicIcon = AcademicConnections;
  setUpIcon = SetUpIcon;
  materialsIcon = MaterialsIcon;
  downloadIcon = DownloadIcon;
  infoIcon = InfoIcon;
  chipRemoveIcon = ChipRemoveIcon;
  noResultsIcon = NoResultsIcon;
  tagIcon = TagIcon;
  indeterminateCheckboxIcon = IndeterminateCheckboxIcon;
  collectionsStore = collectionsStore;

  itemsPerPage: number = 24;

  params: string = '';
  facets: any;

  isAdmin: boolean = false;

  isMobileView: boolean = false;

  isEmptyState: boolean = false;

  @Prop()
  resourceIdQuery: string | undefined;

  subscriptionFilter: string = '';

  initialPage: number | undefined;
  customContentCollectionsEnabled = false;

  @Prop({ default: false })
  showCollectionsFlyoutQuery!: boolean;

  showCollectionsFlyout: boolean = false;

  userSavedContent: Set<string> = new Set<string>();

  cmsLxEnhancementsEnabled = false;

  highlightedContentItem: ContentCardData | null = null;

  /* istanbul ignore next */
  get lxEnabled() {
    return programListStore.appSetting;
  }
  @Watch('lxEnabled', { immediate: true, deep: true })
  cmsFeatureFlag(store: any) {
    if (checkCMSEnabledReRoute(store)) {
      this.$router.push({ path: '/' });
    }
  }

  get pageData() {
    return catalogFilterStore.getCatalogPageData;
  }

  get subscriptionCategories() {
    return programListStore.cmsSubscriptionCategories;
  }

  get cmsCourseStatus() {
    return accountListStore.cmsCourseStatus;
  }

  get highlightedContentId(): string | undefined {
    return this.highlightedContentItem
      ? this.highlightedContentItem.id
      : undefined;
  }

  @Watch("subscriptionCategories", { deep: true, immediate: true })
  subcriptionCategoryWatch(categories: Array<string>) {
    if (categories === undefined || categories.length === 0 || programListStore.cmsLicensesList.length === 0) {
      // If there is are no categories set we need to fetch, this should not happen in the normal flow.
      programListStore.setCmsSubscriptionData();
    }
    else {
      this.subscriptionFilter = this.getSubscriptionFilter();
      this.doSearch();
    }
  }

  getSubscriptionFilter() {
    const subscriptionFilters = programListStore.cmsLicensesList.map(({ subscriptionName, subscriptionGrades }) => {
      const subscriptionNameFilter = `subscriptionNames: "${subscriptionName}"`;

      if (!this.cmsLxEnhancementsEnabled || subscriptionGrades.length === 0) {
        return subscriptionNameFilter;
      }

      return ['noGrades', ...subscriptionGrades]
        .map((grade) => `computedSubscriptionNames: "${subscriptionName}_${grade}"`)
        .join(' OR ');
    });

    return subscriptionFilters.join(' OR ');
  }

  getSubscriptionsFromCategory(category: string) {
    switch (category) {
      case APP_CONST.THE_ESSENTIALS_CATEGORY:
        return APP_CONST.THE_ESSENTIALS_SUBSCRIPTIONS;
      case APP_CONST.ACADEMIC_CATEGORY:
        return APP_CONST.ACADEMIC_SUBSCRIPTIONS;
      case APP_CONST.ENRICHMENT_CATEGORY:
        return APP_CONST.ENRICHMENT_SUBSCRIPTIONS;
      case APP_CONST.ASSESSMENT_CATEGORY:
        return APP_CONST.ASSESSMENT_SUBSCRIPTIONS;
      default:
        return [];
    }
  }

  getPermanentFilters() {
    const audience = this.isAdmin ? '' : '(audiences: "Staff")';
    const subscription = audience.length ? ` AND ${this.subscriptionFilter}` : this.subscriptionFilter;
    return `${audience} ${subscription}`;
  }

  getFilterString() {
    let filterString = this.getPermanentFilters();
    if (filterString.length > 0 && this.pageData.filterString.length > 0) {
      filterString += ' AND ' + this.pageData.filterString;
    } else if (!filterString.length && this.pageData.filterString.length > 0) {
      //No Permanent filters only page filters
      filterString = this.pageData.filterString;
    }
    return filterString;
  }

  async doSearch() {
    this.pageData.loading = true;
    if (this.subscriptionFilter.length === 0) {
      // Don't search before subscription filters are set
      return;
    }
    await AlgoliaIndex.search(this.pageData.searchQuery, {
      page: this.pageData.currentPage,
      hitsPerPage: this.itemsPerPage,
      facets: ['*'],
      filters: this.getFilterString()
      // @TODO: Apply selected facets here
    }).then(({ hits, nbHits, nbPages, params, facets }) => {
      const newCards = getCourseCardStatus(algoliaToCardData(hits as Array<AlgoliaHitObject>), this.cmsCourseStatus);
      this.pageData.currentItems = newCards;
      this.pageData.totalItems = nbHits;
      this.pageData.totalPages = nbPages;
      this.params = params;
      this.pageData.facets = algoliaFacets(facets, this.pageData.selectedFacets);
      window.scrollTo(0, 0);
    }).finally(() => {
      this.pageData.loading = false;
    });
  }

  async beforeMount() {
    const highestRole = Number(APP_UTILITIES.getCookie("highest_role"));
    this.isAdmin = highestRole !== null && highestRole < APP_CONST.SEVEN;
    if (!this.isAdmin) {
      this.pageData.constantFacets = [{ facet: 'audiences', label: 'Staff' }];
    }
    await programListStore.setCmsSubscriptionData();
    this.subscriptionFilter = this.getSubscriptionFilter();
    accountListStore.setAccountCourseStatuses();
    this.$watch('cmsCourseStatus', this.doSearch, { deep: true });
    await APP_UTILITIES.waitForLDClient();
    this.customContentCollectionsEnabled = await APP_UTILITIES.getFeatureFlag(
      APP_CONST.FEATURE_KEYS.customContentCollections
    );
  }

  mounted() {
    this.showCollectionsFlyout = this.showCollectionsFlyoutQuery;
    const subscriptionCategory = this.$route.query.subscriptionCategory ? this.$route.query.subscriptionCategory : null;
    const tag = this.$route.query.tag ? this.$route.query.tag : null;
    this.doSearch().then(() => {
      if (subscriptionCategory) {
        this.pageData.queryFacets.push({
          label: subscriptionCategory,
          facet: 'subscriptionCategories',
        });
      }
      if (tag) {
        this.pageData.queryFacets.push({
          label: tag,
          facet: 'tags',
        });
      }
      if (this.pageData.queryFacets.length > 0) {
        this.handleFacetsUpdate();
      }

    });
    this.handleResize();
    this.initialPage = this.pageData.currentPage;
    this.getUserCollectionsContent();
  }

  getDataForPage(page: number) {
    this.pageData.currentPage = page - 1;
    this.doSearch();
  }

  handleFacetsUpdate() {
    // Set selected facets from facets object
    this.pageData.selectedFacets = [...this.pageData.queryFacets];
    Object.keys(this.pageData.facets).forEach(facetKey => {
      const facet = this.pageData.facets[facetKey];
      facet.values.forEach((facetItem: AlgoliaFacetValue) => {
        if (facetItem.selected) {
          this.pageData.selectedFacets.push({
            label: facetItem.value,
            facet: facetKey,
          });
        }
      });
    });
    // Convert selectedFacets to objects keyed by facet
    const selectedFacetsObject: any = {};
    this.pageData.selectedFacets.forEach((facet: { facet: any; label: any; }) => {
      if (!selectedFacetsObject[facet.facet]) {
        selectedFacetsObject[facet.facet] = [];
      }
      selectedFacetsObject[facet.facet].push(facet.label);
    });
    // Generate an array of strings for each selected facet
    const selectedFacetsArray: any = [];
    Object.keys(selectedFacetsObject).forEach(facetKey => {
      const facetValues = selectedFacetsObject[facetKey];
      const facetStrings = facetValues.map((facetValue: any) => {
        return `${facetKey}:"${facetValue}"`;
      });
      const facetString = `(${facetStrings.join(' OR ')})`;
      selectedFacetsArray.push(facetString);
    });
    // Join selected facets with AND
    const selectedFacetsString = selectedFacetsArray.join(' AND ');
    this.pageData.filterString = selectedFacetsString;
    this.doSearch();
    // Dedupe query facets and selected facets
    this.pageData.queryFacets = this.pageData.queryFacets.filter(
      (item: { label: any; facet: any }, index: number, self: any) =>
        index === self.findIndex((t: { label: any; facet: any }) => t.label === item.label && t.facet === item.facet)
    );
    this.pageData.selectedFacets = this.pageData.selectedFacets.filter(
      (item: { label: any; facet: any }, index: number, self: any) =>
        index === self.findIndex((t: { label: any; facet: any }) => t.label === item.label && t.facet === item.facet)
    );
  }

  closeMobileFilters() {
    this.pageData.filtersOpen = false;
    this.filtersClose();
    this.handleFacetsUpdate();
  }

  handleResize() {
    const width = window.innerWidth;
    if (width < 578) { // 577 is when cards wrap to a single column
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  async created() {
    this.cmsLxEnhancementsEnabled = await APP_UTILITIES.getFeatureFlag(APP_CONST.FEATURE_KEYS.cmsLxEnhancements);

    window.addEventListener('resize', this.handleResize);
  }

  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  }

  getCopyrightMessage = getCopyrightMessage;

  getResultsText() {
    return this.pageData.hasFilters ? 'Filtered Results' : 'Results';
  }

  removeChip(data: any) {
    const newFacets = this.pageData.selectedFacets.filter(
      (item: { label: any; facet: any }) => item.label !== data.label && item.facet !== data.facet
    );
    this.pageData.selectedFacets = newFacets;
    // Update facets to reflect removed facet chip
    Object.keys(this.pageData.facets).forEach(facetKey => {
      const facet = this.pageData.facets[facetKey];
      facet.values.forEach((facetItem: AlgoliaFacetValue) => {
        if (facetItem.value === data.label) {
          facetItem.selected = false;
        }
      });
    });
    this.pageData.queryFacets = this.pageData.queryFacets.filter(
      (item: { label: any; facet: any }) => item.label !== data.label && item.facet !== data.facet
    );
    this.handleFacetsUpdate();
  }

  checkEnterSearch(event: KeyboardEvent, value: any) {
    this.pageData.searchQuery = value;
    if (event.key === 'Enter') {
      this.doSearch();
    } else if (event.key === 'Backspace') {
      if (value.length === 0) {
        this.doSearch();
      }
    }
  }

  closeQuery() {
    this.pageData.searchQuery = '';
    this.doSearch();
  }

  filtersOpen() {
    this.pageData.filtersOpen = true;
    // Apply class to the HTML tag to prevent scrolling
    const root = document.getElementsByTagName('html')[0];
    root.classList.add('filters-open');
  }

  filtersClose() {
    this.pageData.filtersOpen = false;
    // Remove class from the HTML tag to allow scrolling
    const root = document.getElementsByTagName('html')[0];
    root.classList.remove('filters-open');
  }

  public handleCollectionsFlyoutClose() {
    this.showCollectionsFlyout = false;
    this.highlightedContentItem = null;
  }

  public handleCollectionsFlyoutOpen() {
    this.showCollectionsFlyout = true;
  }

  public clearSelectedContents() {
    collectionsStore.clearSelection();
  }

  async getUserCollectionsContent() {
    const { data: { items: collections } } = await getUserCollections({ max: -1, page: 1 });
    collections.forEach(collection => {
      collection.posts.forEach(contentID => this.userSavedContent.add(contentID));
    });
  }

  onCollectionsFlyoutSave({ contentSaved, contentRemoved, selectedCollections }: { contentSaved: string[]; contentRemoved: string[]; selectedCollections: ContentCardData[]; deselectedCollections: ContentCardData[] }): void {
    this.showCollectionsFlyout = false;
    this.highlightedContentItem = null;
    contentSaved.forEach(contentId => this.userSavedContent.add(contentId));

    if (selectedCollections.length === 0) {
      contentRemoved.forEach(contentId => this.userSavedContent.delete(contentId));
    }
  }

  handleCollectionSavedClick(cardData: ContentCardData): void {
    this.highlightedContentItem = cardData;
    this.showCollectionsFlyout = true;
  }
}
