import { CollectionsDto, CreateUserCollectionRequest, UpdateCollectionRequest } from '@/Model/collections/types';
import { ToastPosition } from '@/Model/toastPosition';
import { ToastType } from '@/Model/toastType';
import { AnalyticsInjectionKey, AnalyticsService, analyticsEventNames } from '@/analytics';
import CollectionCardComponent from '@/commoncomponents/CollectionCardComponent/CollectionCardComponent.vue';
import BouncingPreloaderComponent from '@/commoncomponents/bouncingpreloadercomponent/BouncingPreloaderComponent.vue';
import ConfigureLearningLibraryCollection from '@/components/learningLibrary/ConfigureLearningLibraryCollection.vue';
import CollectionForm from '@/components/myCollectionsFlyout/CollectionForm/CollectionForm.vue';
import { AlgoliaIndex } from '@/services/cms/algoliaService';
import {
  createUserCollection,
  getUserCollections,
  updateCollection
} from '@/services/collections/api';
import collectionsStore from '@/store/modules/collectionsStore';
import { AlgoliaHitObject, CollectionCardData } from '@/utilities/cmsUtilities';
import { collectionNameValidationRules } from '@/utilities/collectionsUtilities';
import APP_UTILITIES from '@/utilities/commonFunctions';
import { AxiosError } from 'axios';
import { ValidationProvider } from 'vee-validate';
import { ProviderInstance } from 'vee-validate/dist/types/types';
import Vue from 'vue';
import InfiniteLoading from 'vue-infinite-loading';
import { Component, Inject, Prop, Ref } from 'vue-property-decorator';

@Component({
  name: 'MyCollectionsFlyout',
  components: {
    CollectionForm,
    ConfigureLearningLibraryCollection,
    CollectionCardComponent,
    BouncingPreloaderComponent: BouncingPreloaderComponent,
    InfiniteLoading,
    ValidationProvider,
  }
})
export default class MyCollectionsFlyout extends Vue {
  @Prop({ type: Boolean })
  isVisible: boolean = false;

  @Prop({ type: String, default: '' })
  highlightedContentId!: string;

  @Inject(AnalyticsInjectionKey)
  private readonly analyticsService!: AnalyticsService;

  @Ref('nameValidationProvider')
  public nameValidationProvider?: ProviderInstance;

  public collections: CollectionsDto[] = [];
  public selectedCollections: CollectionCardData[] = [];
  public deselectedCollections: CollectionCardData[] = [];
  public collectionThumbnailsMapping: Record<number, string[]> = {};

  public isLoadingCollections: boolean = false;
  public isSavingCollections: boolean = false;
  public getUserCollectionsErrorMessage: string | null = null;

  public totalItems: number = 0;
  public pagination = {
    page: 0,
    max: 25,
  };

  public showForm: boolean = false;
  public collectionName: string = '';

  contentSaved: Set<string> = new Set([]);
  get totalItemsSaved() {
    return this.contentSaved.size;
  }

  contentRemoved: Set<string> = new Set([]);
  get totalItemsRemoved() {
    return this.contentRemoved.size;
  }

  public get collectionsCardData(): CollectionCardData[] {
    return this.collections.map(item => ({
      title: item.name,
      collectionId: item.id.toString(),
      isLocked: item.isLocked,
      totalItems: (
        item.posts
          ? item.posts.length
          : 0
      ),
      items: item.posts,
      contentImages: this.collectionThumbnailsMapping[item.id] || [],
      learningLibraryEditing: true
    }));
  }

  get nameValidationRules(): Record<string, unknown> {
    return collectionNameValidationRules();
  }

  get selectedContent(): string[] {
    return this.highlightedContentId
      ? [this.highlightedContentId]
      : collectionsStore.selectedContent;
  }

  private getPayload(): UpdateCollectionRequest[] {
    const selectedContent = this.selectedContent;
    const userId = APP_UTILITIES.getUserID();
    return [
      ...this.selectedCollections.map(c => {
        const collectionsPosts = new Set(c.items);
        selectedContent.forEach(c => {
          if (!collectionsPosts.has(c)) {
            collectionsPosts.add(c);
            this.contentSaved.add(c);
          }
        });
        return {
          id: parseInt(c.collectionId),
          name: c.title,
          isLocked: c.isLocked,
          posts: Array.from(collectionsPosts.values()),
          userId,
        };
      }),
      ...this.deselectedCollections.map(c => {
        const collectionsPosts = new Set(c.items);
        selectedContent.forEach(c => {
          if (collectionsPosts.has(c)) {
            collectionsPosts.delete(c);
            this.contentRemoved.add(c);
          }
        });
        return {
          id: parseInt(c.collectionId),
          name: c.title,
          isLocked: c.isLocked,
          posts: Array.from(collectionsPosts.values()),
          userId,
        };
      })
    ];
  }

  public async handleInfiniteLoader($state: {
    loaded: () => void;
    complete: () => void;
  }): Promise<void> {
    try {
      this.isLoadingCollections = true;
      const newPage = this.pagination.page + 1;
      const { data: { items, totalItems } } = await getUserCollections({
        page: newPage,
        max: this.pagination.max
      });

      await this.mapCollectionsThumbnails(items);

      this.collections = [
        ...this.collections,
        ...items
      ];
      this.pagination.page = newPage;
      this.totalItems = totalItems;

      if (this.collections.length === totalItems) {
        $state.complete();
      }
      else {
        $state.loaded();
      }
    }
    catch (e) {
      this.getUserCollectionsErrorMessage = (e as AxiosError | Error).message;
      throw e;
    }
    finally {
      this.isLoadingCollections = false;
    }
  }

  public handleCancel() {
    this.$emit('cancel');
    this.setDefaults();
  }

  public handleCollectionSelected(options: { selected: boolean; data: CollectionCardData }) {
    const collectionIndex = this.selectedCollections.findIndex(c => c.collectionId === options.data.collectionId);
    if (options.selected) {
      if (collectionIndex === -1) {
        this.selectedCollections.push(options.data);
      }

      const deselectedCollectionIndex = this.deselectedCollections.findIndex(c => c.collectionId === options.data.collectionId);

      if (deselectedCollectionIndex !== -1) {
        this.deselectedCollections.splice(deselectedCollectionIndex, 1);
      }
    }
    else {
      if (collectionIndex !== -1) {
        this.selectedCollections.splice(collectionIndex, 1);
        this.deselectedCollections.push(options.data);
      }
    }
  }

  public async handleSave() {
    const payload = this.getPayload();
    const promises = payload.map(p => updateCollection(p));

    try {
      /* Create a new collection with the provided name and add the selected content */
      if (this.showForm && this.nameValidationProvider) {
        const { valid } = await this.nameValidationProvider.validate();
        if (!valid) {
          return;
        }
        const newCollection: CreateUserCollectionRequest = {
          name: this.collectionName,
          isLocked: false,
          userId: APP_UTILITIES.getUserID(),
          posts: this.selectedContent
        };

        await createUserCollection(newCollection);
        this.selectedContent.forEach(contentId => this.contentSaved.add(contentId));
      }

      this.isSavingCollections = true;
      const response = await Promise.all(promises);
      if (response) {
        this.analyticsService.track(analyticsEventNames.LEARNING_LIBRARY_CONTENT_ADDED_TO_A_COLLECTION);
        if (!this.highlightedContentId) {
          collectionsStore.clearSelection();
        }

        let message = `${this.contentSaved.size > 0
          ? this.totalItemsSaved
          : 'No Items'} Saved`;

        if (this.contentRemoved.size > 0 && this.selectedCollections.length === 0) {
          message = `${message}, ${this.totalItemsRemoved} Removed`;
        }

        APP_UTILITIES.showToastMessage(message, ToastType.Success, ToastPosition.BottomCenter);
      }
      this.resetValidation();
      this.collectionName = '';
      this.showForm = false;
      this.$emit('save', {
        contentSaved: this.contentSaved.values(),
        contentRemoved: this.contentRemoved.values(),
        selectedCollections: this.selectedCollections,
        deselectedCollections: this.deselectedCollections,
      });
    }
    catch (e) {
      console.error('Update error', e);
    }
    finally {
      this.isSavingCollections = false;
      this.setDefaults();
    }
  }

  private setDefaults() {
    this.collections = [];
    this.pagination.page = 0;
    this.selectedCollections = [];
    this.deselectedCollections = [];
    this.contentSaved.clear();
    this.contentRemoved.clear();
  }

  public toggleForm() {
    this.showForm = !this.showForm;
  }

  resetValidation(): void {
    this.nameValidationProvider && this.nameValidationProvider.reset();
  }

  getHighlightedCardStatus(card: CollectionCardData): boolean {
    return card.items
      ? card.items.includes(this.highlightedContentId)
      : false;
  }

  private async mapCollectionsThumbnails(collections: CollectionsDto[]): Promise<void> {
    for (const collection of collections) {
      // For collections with 4 or more items: Display the last 4 added thumbnails
      // For collections with 1-3 items: Display last added thumbnail
      const itemsToDisplayIndex = collection.posts.length > 3
        ? -4
        : -1;
      const posts = collection.posts.slice(itemsToDisplayIndex);
      const { results } = await AlgoliaIndex.getObjects<AlgoliaHitObject>(posts);

      const thumbnails: string[] = [];
      for (const result of results) {
        if (!result || !result.contentImageUrl) {
          continue;
        }
        thumbnails.push(result.contentImageUrl);
      }
      this.collectionThumbnailsMapping[collection.id] = thumbnails;
    }
  }
}
